.serviceimage{
    height: 230px;
 object-fit: cover;
 border-radius: 20px;
 width: 310px;
}
.news-content{
    padding: 20px;
    margin-left: 3px;
}
.news-content p{
    margin-bottom: 15px;
    text-align: justify;
    margin-top: 20px;

}
.news-content a{
    font-size: 14px;
    background-color: #06397d;
    padding: 10px 20px;
    color: white;
    transition: all 0.5s ease-in;
}
.news-content a:hover{
    font-size: 14px;
    border: 1px solid #34495e;
    border-top-right-radius: 20px;
    background-color: transparent;
    color: #34495e;
}
@media screen and (max-width: 768px)
{.serviceimage {
    width: 280px;
    height: 200px;
}}